import { Link } from 'gatsby';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Header = () => (
  <header className="bg-gray-800 ">
    <div className="relative aspect-w-16 aspect-h-3 overflow-hidden select-none">
      <StaticImage
        className="!absolute inset-0 w-full object-cover"
        src="../images/mountain.jpg"
        // formats={['AUTO', 'WEBP', 'AVIF']}
        alt="DQBT logo"
        quality={95}
      />
      <div
        className="absolute inset-0 opacity-70"
        style={{
          backgroundImage:
            'linear-gradient(to top, #0d0d0e, rgb(133 109 112 / 30%), rgb(253 164 175 / 50%))',
          backdropFilter: 'blur(1px)',
        }}
      />
      <div className="h-full flex items-center">
        <Link to="/" className="text-white flex-1 flex justify-center">
          <StaticImage
            className="rounded-full object-cover w-1/6 pointer-events-none"
            src="../images/dqbt-logo.jpg"
            // formats={['AUTO', 'WEBP', 'AVIF']}
            alt="DQBT logo"
            quality={95}
          />
        </Link>
      </div>
    </div>
  </header>
);

export default Header;
